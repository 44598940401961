import { useEffect } from "react";
import { useRouter } from "next/router";
import {
  getAuth,
  isAuthenticated,
  removeAuth,
} from '@services/identity.service';
import { getUserStatus } from "@services/user.service";

const PUBLIC_ROUTES = ['/', '/reset-password', '/reset-password/verify'];

const useAuthCheck = () => {
  const router = useRouter();

  useEffect(() => {
    const checkAuthStatus = async () => {
      const auth = getAuth();
      // console.log("🚀  auth:", auth)

      if (!isAuthenticated(auth) && !PUBLIC_ROUTES.includes(router.pathname)) {
        router.replace("/"); // Redirect to login if not authenticated
        return;
      }

      if (auth) {
        try {
          const response = await getUserStatus(auth.id); // Poll API for status
          console.log("🚀 ~response:", response)
          
          if (response.status && response.entity.status !== "ACTIVE") {
            removeAuth(); // Remove session if status changed
            router.replace("/");
          } else {
            // setAuth(response.entity); // Update auth state if still active
          }
        } catch (error) {
          console.error("Error fetching user status:", error);
        }
      }
    };

    const interval = setInterval(checkAuthStatus, 15000); // Poll every 5 seconds
    return () => clearInterval(interval); // Cleanup on unmount
  }, [router]);

  return null;
};

export default useAuthCheck;
